import { Controller } from "@hotwired/stimulus"

import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range"
import Highcharts from "highcharts/highstock"

window.moment = moment

Highcharts.setOptions({
  lang: {
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    shortMonths: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    weekdays: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
  }
})

const first = array => array[0]
const last = array => array[array.length - 1]

export default class extends Controller {
  static targets = [ "chart" ]
  static values = { url: String }

  connect() {
    this.loading = true

    fetch(this.urlValue)
      .then(response => response.ok && response.json())
      .then(data => this.loadChart(data))
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
      this.chart = null
    }
  }

  loadChart(data) {
    // data.series.push([moment().add(15, 'minutes').valueOf(), null])

    this.chart = Highcharts.stockChart(this.chartTarget, {
      xAxis: {
        type: 'datetime',
        events: { afterSetExtremes: this.afterSetExtremes.bind(this) },
        minRange: 1 * 60 * 1000, // 1 minutes
        ordinal: false
      },
      navigator: {
        adaptToUpdatedData: false,
        // xAxis: {
        //   // min: first(data.series)[0],
        //   // max: last(data.series)[0]
        //   min: data.start,
        //   max: data.end
        //   // min: data.series[0][0],
        //   // max: moment().add(15, 'minutes').valueOf()
        // },
        series: {
          data: this.seriesData(data, 0)
        }
      },
      scrollbar: {
        liveRedraw: false,
      },
      yAxis: this.chartYAxis(data),
      series: this.chartSeries(data),
      chart: {
        type: 'line',
        zoomType: 'x',
        animation: false
      },
      rangeSelector: {
        buttons: [
          {
            type: 'hour',
            count: 3,
            text: '3h'
          }, {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'day',
            count: 3,
            text: '3d'
          }, {
            type: 'week',
            count: 1,
            text: '1w'
          }, {
            type: 'month',
            count: 1,
            text: '1m'
          }, {
            type: 'month',
            count: 3,
            text: '3m'
          },{
            type: 'all',
            text: 'All'
          }
        ],
        selected: 0
      },
      credits: { enabled: false },
      legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top'
      },
      time: {
        timezone: 'Europe/Berlin',
        useUTC: false
      },
      tooltip: {
        shared: true
      },
      accessibility: {
        enabled: false
      }
    })

    this.loading = false
    this.chart.hideLoading()

    if (this.element.getElementsByClassName("highcharts-button-pressed").length > 0) {
      // TODO:
      // Rails.fire(this.element.getElementsByClassName("highcharts-button-pressed")[0], "click")
    }
  }

  seriesData(data, index) {
    let seriesData = data.series
      .map(item => [item[0], item[index + 1]])
      .filter(item => item[1] != null && item[1] != undefined)

    // let seriesData = data.series.reduce((series, item) => {
    //   let value = item[index + 1]
    //   if (value != undefined && value != null) {
    //     series.push([item[0], value])
    //   }
    //   return series
    // }, [])

    if (first(seriesData) == undefined || first(seriesData)[0] > data.start) {
      seriesData.unshift([data.start, null])
    }
    if (last(seriesData) == undefined || last(seriesData)[0] < data.end) {
      seriesData.push([data.end, null])
    }
    return seriesData
  }

  drawChart(data,) {
    data.columns.forEach((_column, index) => {
      // this.chart.series[index].update({
      //   data: this.seriesData(data, index)
      // })
      this.chart.series[index].setData(this.seriesData(data, index), false, false, false)
      this.chart.series[index].update({ gapSize: data.gap_size }, false)
    })
    this.chart.redraw()
    this.loading = false
    this.chart.hideLoading()
  }

  afterSetExtremes(event) {
    if (this.loading == true) return;
    this.loading = true
    this.chart.showLoading('Bitte warten...')

    let url = new URL(this.urlValue)
    url.searchParams.append("start", Math.round(event.min))
    url.searchParams.append("end", Math.round(event.max))

    fetch(url)
      .then(response => response.ok && response.json())
      .then(data => this.drawChart(data))
  }

  chartYAxis(data) {
    // ## axes per column
    // return data.columns.map((column, index) => {
    //   return {
    //     title: {
    //       text: index < 2 ? column.name : null
    //     },
    //     labels: {
    //       enabled: index < 2 ? true : false
    //     },
    //     opposite: !(index % 2 == 0),
    //     // floor: column.extrema.min,
    //     // ceiling: column.extrema.max,
    //     softMin: column.extrema.min,
    //     softMax: column.extrema.max,
    //     minorTicks: false,
    //     startOnTick: true,
    //     endOnTick: true,
    //     tickPixelInterval: 30
    //   }
    // })
    // ## axis per unit
    return data.axes.map((axis, index) => {
      return {
        title: {
          text: index < 2 ? axis.unit : null
        },
        labels: {
          enabled: index < 2 ? true : false
        },
        opposite: !(index % 2 == 0),
        // floor: axis.extrema.min,
        // ceiling: axis.extrema.max,
        softMin: axis.extrema.min,
        softMax: axis.extrema.max,
        minorTicks: false,
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 30
      }
    })
  }
  chartSeries(data) {
    return data.columns.map((column, index) => {
      return {
        // yAxis: index, // axis per column
        yAxis: data.axes.map(axis => axis.unit).indexOf(column.unit), // axis per unit
        name: column.name,
        data: this.seriesData(data, index),
        tooltip: {
          valueDecimals: column.scale,
          valueSuffix: ` ${column.unit}`
        },
        color: column.color,
        animation: false,
        gapSize: data.gap_size,
        gapUnit: "value",
        step: "left"
      }
    })
  }
}
